/**
 * external libs
 */
import React, { PropsWithChildren, useMemo, useCallback, useState, useEffect, useContext } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { GoldText, SubMenuLink, Ico } from '../common-components/ui'
import Portal from '../common-components/portal/Portal'
import Logo from '../assets/svg/slide_logo.svg'
/**
 * utils
 */
import { langs } from '../utils/i18n/i18n.constants'
import { publicRoutes } from '../routers/paths'
import { SLIDES } from '../common-components/header/VHeader'
import { SLIDES as SLIDES_HORIZONTAL } from '../common-components/horizontal/Horizontal'
/**
 * types
 */
import { RouteType } from '../types'
/**
 * styles
 */
import styles from './layouts.module.scss'
import headerStyles from '../common-components/header/header.module.scss'
import footerStyles from '../common-components/footer/footer.module.scss'
import { GlobalContentContext, GlobalContext } from '../App'

const OutternalLayout: React.FC<PropsWithChildren> = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const routeData = useMemo<RouteType | undefined>(
        () => publicRoutes.find((r: RouteType) => r.path === location.pathname),
        [location]
    )

    const [open, setOpen] = useState(false)
    const [progress, setProgress] = useState(0)
    const [horizontal, setHorizontal] = useState<boolean | null>(null)
    const { handleSetLanguage } = useContext(GlobalContentContext)

    const menuCloseHandler = useCallback(
        (idx: number) => {
            navigate(`/#${idx || ''}`)
            setOpen(false)
        },
        [setOpen]
    )

    useEffect(() => {
        const resizeHandler = () => {
            const isHorizontal = window.innerWidth >= window.innerHeight

            if (isHorizontal) {
                document.querySelector('html')?.classList.add('horizontal')
            } else {
                document.querySelector('html')?.classList.remove('horizontal')
            }

            setHorizontal(isHorizontal)
        }

        window.addEventListener('resize', resizeHandler)
        resizeHandler()

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [setHorizontal])

    return (
        <div className={styles.outternalLayout}>
            <Helmet>
                <title>{routeData?.title ?? '404'}</title>
                <meta name="description" content={routeData?.description ?? ''} />
            </Helmet>

            <header className={`${headerStyles.vheader} ${styles.outternalLayout__vheader}`}>
                <div className="container container_relative">
                    <div className={headerStyles.vheader__container}>
                        <div className={headerStyles.vheader__left}>
                            <img
                                src={Logo}
                                className={styles.outternalLayout__logo}
                                onClick={() => menuCloseHandler(0)}
                            />
                        </div>

                        <div className={headerStyles.vheader__right} onClick={() => setOpen((pre) => !pre)}>
                            <GoldText>{!open ? t('menu') : t('close')}</GoldText>

                            <div className={`${headerStyles.burger} ${open ? headerStyles.active : ''}`}>
                                <div className={headerStyles.burger__line} />
                                <div className={headerStyles.burger__line} />
                                <div className={headerStyles.burger__line} />
                            </div>
                        </div>
                    </div>
                </div>

                <Portal open={open} vertical={true} onClose={() => setOpen(false)}>
                    <nav className={`container ${headerStyles.vheader__nav}`}>
                        {(horizontal ? SLIDES_HORIZONTAL : SLIDES).map((title, idx) => (
                            <SubMenuLink
                                key={title}
                                title={t(title)}
                                onClickHandler={() => menuCloseHandler(idx)}
                                to={'/'}
                            />
                        ))}
                    </nav>
                </Portal>
            </header>

            <Outlet />

            <footer className={footerStyles.footer}>
                <div className={`container ${footerStyles.footer__container}`}>
                    <GoldText>{''}</GoldText>

                    <div className={`${footerStyles.footer__menu} ${footerStyles.footer__menu_long}`}>
                        <a href="mailto:info@schmidtspirits.com" className={footerStyles.footer__mail}>
                            <Ico
                                ico={
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.6155 19C4.15517 19 3.77083 18.8458 3.4625 18.5375C3.15417 18.2292 3 17.8448 3 17.3845L3 6.6155C3 6.15517 3.15417 5.77083 3.4625 5.4625C3.77083 5.15417 4.15517 5 4.6155 5L19.3845 5C19.8448 5 20.2292 5.15417 20.5375 5.4625C20.8458 5.77083 21 6.15517 21 6.6155L21 17.3845C21 17.8448 20.8458 18.2292 20.5375 18.5375C20.2292 18.8458 19.8448 19 19.3845 19L4.6155 19ZM12 12.1155L4 6.8845L4 17.3845C4 17.564 4.05767 17.7115 4.173 17.827C4.2885 17.9423 4.436 18 4.6155 18L19.3845 18C19.564 18 19.7115 17.9423 19.827 17.827C19.9423 17.7115 20 17.564 20 17.3845L20 6.8845L12 12.1155ZM12 11L19.6923 6L4.30775 6L12 11ZM4 6.8845L4 6L4 17.3845C4 17.564 4.05767 17.7115 4.173 17.827C4.2885 17.9423 4.436 18 4.6155 18L4 18L4 6.8845Z" />
                                    </svg>
                                }
                                isButton={true}
                            />
                        </a>

                        <div className={footerStyles.footer__langs}>
                            {Object.keys(langs).map((lang) => (
                                <button
                                    key={lang}
                                    type="button"
                                    className={`${footerStyles.footer__lang} ${lang === i18n.language ? footerStyles.active : ''}`}
                                    onClick={() => handleSetLanguage(lang)}>
                                    {lang}
                                    <span className={footerStyles.footer__lang__hover}>{lang}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default OutternalLayout
